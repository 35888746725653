
import Vue from "vue";
import Component from "vue-class-component";
import Player from "@/components/PlayerView.vue";

@Component({
  components: {
    Player
  }
})
export default class MainLeft extends Vue {
  bus = new Vue();

  emitSwitch() {
    this.bus.$emit("cleanup");
    this.$emit("switch-left");
  }
}
