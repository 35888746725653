
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data() {
    return {
      ready: false,
      switched: "/"
    };
  },
  methods: {
    switchLeft() {
      if (this.switched === "/") {
        this.switched = "/about";
      } else if (this.switched === "/about") {
        this.switched = "/";
      }
      this.$router.push(this.switched);
    }
  },
  mounted() {
    this.ready = true;
  }
});
