
import Vue from "vue";
import Component from "vue-class-component";

const SNSItemFact = Vue.extend({
  props: {
    icon: Array,
    text: String,
    url: String
  }
});

@Component
export default class SNSItem extends SNSItemFact {}
