
import Vue from "vue";
import Component from "vue-class-component";
import SNSItem from "@/components/SNSItem.vue";

@Component({
  components: {
    SNSItem
  }
})
export default class AboutLeft extends Vue {
  sns = [
    {
      icon: ["fas", "language"],
      text: "zh_hans-4, zh_hant-2, en-3, ja-0"
    },
    {
      icon: ["fab", "github"],
      text: "@outloudvi",
      url: "https://github.com/outloudvi"
    },
    {
      icon: ["fab", "telegram"],
      text: "@outvi",
      url: "https://t.me/outvi"
    },
    {
      icon: ["fab", "mastodon"],
      text: "@ov@mk.outv.im",
      url: "https://mk.outv.im/@ov"
    },
    {
      icon: ["fas", "key"],
      text: "0xA725CB57CA65CAFE",
      url:
        "https://keys.openpgp.org/search?q=7A9543829E475D7D3826B08DA725CB57CA65CAFE"
    },
    {
      icon: ["fas", "envelope"],
      text: "hello [at] outv.im"
    }
  ];
  emitSwitch() {
    this.$emit("switch-left");
  }

  mounted() {
    document.title = "About | outv.im";
  }
}
